import { Card } from 'entities/card/model';

import { axiosInstance } from 'shared/api/axios.defaults';
import type { TableResponse } from 'shared/typings';

export class CardApi {
  static readonly KEY = 'card';

  static async getCards(): Promise<TableResponse<Card>> {
    const { data } = await axiosInstance.get(`/card/cards`);
    return data;
  }

  static async addCard(params: {
    newCard: string;
  }): Promise<{ success: boolean }> {
    const { data } = await axiosInstance.post(`/card/attach-new-card`, params);
    return data;
  }
}
