import { cx } from '@emotion/css';

import { useState } from 'react';

import { PayDialog } from 'features/pay/ui';

import { Button } from 'shared/ui';

export const InstallmentInfo = () => {
  const [isOpen, setIsOpen] = useState(false);

  // const { data } = useQuery({
  //   queryKey: [ProfileApi.KEY],
  //   queryFn: ProfileApi.getProfile,
  // });
  //
  // if (!data) return <Spinner />;

  return (
    <>
      <div className="flex flex-col justify-center gap-4 rounded-3xl p-8 shadow-2xl">
        <span className="grid grid-cols-[1fr_5fr]">
          <span className="text-lg font-bold">Неизрасходованный лимит: </span>
          <span>35000 рублей потратьте в Кари</span>
        </span>
        <span className="grid grid-cols-[1fr_5fr]">
          <span className="text-lg font-bold">Номер договора:</span>
          <span>11111111</span>
        </span>
        <span className="grid grid-cols-[1fr_5fr]">
          <span className="text-lg font-bold"> Дата оформления:</span>
          <span>
            25.10.2024
            {/*{format(new Date(data.birthDate), 'DD.mmm.YYYY')}*/}
          </span>
        </span>
        <span className="grid grid-cols-[1fr_5fr]">
          <span className="text-lg font-bold">Сумма заказа:</span>
          <span> 20000 рублей</span>
        </span>
        <span className="grid grid-cols-[1fr_5fr]">
          <span className="text-lg font-bold">Сумма в рассрочку:</span>
          <span> 20000 рублей</span>
        </span>
        <span className="grid grid-cols-[1fr_5fr]">
          <span className="text-lg font-bold">Первоначальный платеж: </span>
          <span> 20000 рублей</span>
        </span>
        <span className="grid grid-cols-[1fr_5fr]">
          <span className="text-lg font-bold">Магазин: </span>
          <span>
            <a href="/" className="cursor-pointer underline hover:text-brand-3">
              Кари
            </a>
          </span>
        </span>
        <span>
          <a href="/" className="cursor-pointer underline hover:text-brand-3">
            Ссылка на договор оферту
          </a>
        </span>
        <span className="flex flex-col justify-center gap-1">
          <span className="text-lg font-bold">График платежей</span>
          {[
            '25.09.2024: 5000 рублей',
            '25.09.2024: 5000 рублей',
            '25.09.2024: 5000 рублей',
          ].map((date) => (
            <span className="flex items-center gap-16">
              <span>{date}</span>
              <span>
                <Button
                  className={cx(
                    'inline-flex w-fit items-center gap-2 rounded-md bg-brand-1 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-inner' +
                      'shadow-white/10 focus:outline-none data-[hover]:bg-gray-200 data-[hover]:text-gray-700',
                  )}
                  onClick={() => setIsOpen(true)}
                >
                  Оплатить
                </Button>
              </span>
            </span>
          ))}
          <Button
            className={cx(
              'inline-flex w-fit items-center gap-2 rounded-md bg-brand-1 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-inner' +
                'shadow-white/10 focus:outline-none data-[hover]:bg-gray-200 data-[hover]:text-gray-700',
            )}
            onClick={() => setIsOpen(true)}
          >
            Оплатить досрочно
          </Button>
        </span>
      </div>
      <PayDialog isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </>
  );
};
