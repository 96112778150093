import { faCreditCard } from '@fortawesome/free-solid-svg-icons';

import { InstallmentInfo } from 'pages/installment-page/ui';

import { PageHeading, PageWrapper } from 'shared/ui';

export const InstallmentPage = () => {
  return (
    <PageWrapper className="relative">
      <PageHeading
        icon={faCreditCard}
        title="Текущие рассрочки"
        refetchQueryFilters={[]}
      />
      <InstallmentInfo />
    </PageWrapper>
  );
};
