import type { Order } from 'entities/order';

import { axiosInstance } from 'shared/api/axios.defaults';
import type { TableResponse } from 'shared/typings';

export class OrderApi {
  static readonly KEY = 'order';

  static async getOrders(): Promise<TableResponse<Order>> {
    const { data } = await axiosInstance.get(`/orders`);
    return data;
  }

  static async getOrder(id: string): Promise<Order> {
    const { data } = await axiosInstance.get(`/orders/${id}`);
    return data;
  }
}
