import { cx } from '@emotion/css';

import type { FunctionComponent, HTMLAttributes } from 'react';

export const Spinner: FunctionComponent<HTMLAttributes<HTMLDivElement>> = (
  props,
) => {
  return (
    <span
      role="status"
      className={cx(
        'absolute m-auto inset-0 inline-block h-8 w-8 self-center',
        'justify-self-center animate-spin rounded-full',
        'border-4 border-solid border-current border-e-transparent',
        'align-[-0.125em] text-surface text-brand-1',
        props.className,
      )}
    />
  );
};
