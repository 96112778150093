import { format } from 'date-fns';

import { Order, OrderApi } from 'entities/order';
import { StatusTag } from 'entities/status';

import type { AccessorKey } from 'shared/typings';
import { Table } from 'shared/ui';

export const HistoryTable = () => {
  const columns: AccessorKey<Order & { date_begin: string }>[] = [
    { accessorKey: 'guid' },
    { accessorKey: 'status' },
    { accessorKey: 'date_begin' },
    { accessorKey: 'total_sum' },
  ];

  const thead = (
    <tr>
      <th
        scope="col"
        className="w-[50px] whitespace-nowrap px-6  py-3 text-center"
      >
        Номер договора
      </th>
      <th scope="col" className="whitespace-nowrap px-6 py-3  text-center ">
        Статус
      </th>
      <th scope="col" className="whitespace-nowrap px-6 py-3  text-center ">
        Дата договора
      </th>
      <th scope="col" className="whitespace-nowrap px-6  py-3 text-center">
        Сумма заказа
      </th>
    </tr>
  );

  const renderRow = (order: Order & { date_begin: string }) => {
    return (
      <tr
        key={order.guid}
        className="cursor-pointer border-b border-gray-700 bg-white hover:bg-brand-1/20"
      >
        <td className="px-6 py-4 text-center">{order.guid}</td>
        <td className="px-6 py-4 text-center">
          <StatusTag status={order.status} />
        </td>
        <td className="px-6 py-4 text-center ">
          {format(new Date(order.date_begin), 'DD.mmm.YYYY')}
        </td>
        <td className="px-6 py-4 text-center ">{order.total_sum}</td>
      </tr>
    );
  };

  return (
    <Table
      queryKeys={[OrderApi.KEY, 'getUsers']}
      queryFn={OrderApi.getOrders()}
      columns={columns}
      thead={thead}
      renderRow={renderRow}
    />
  );
};
