import './style.scss';

import ReactPaginate from 'react-paginate';

export const Pagination = ({
  pageCount,
  onChange,
  pageIndex,
}: {
  pageCount: number;
  onChange: (pageIndex: number) => void;
  pageIndex: number;
}) => {
  const onPageChange = (event: { selected: number }) => {
    onChange(event.selected);
  };

  return (
    <ReactPaginate
      forcePage={pageIndex}
      nextLabel="далее >"
      onPageChange={onPageChange}
      pageRangeDisplayed={3}
      marginPagesDisplayed={2}
      pageCount={pageCount}
      previousLabel="< назад"
      pageClassName="page-item"
      pageLinkClassName="page-link"
      previousClassName="page-item"
      previousLinkClassName="page-link"
      nextClassName="page-item"
      nextLinkClassName="page-link"
      breakLabel="..."
      breakClassName="page-item"
      breakLinkClassName="page-link"
      containerClassName="pagination"
      activeClassName="active"
      renderOnZeroPageCount={null}
    />
  );
};
