import { faRefresh } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button } from 'shared/ui';

export const RefreshButton: typeof Button = (props) => {
  return (
    <Button
      className="group size-[32px] rounded-full bg-brand-1 flex-center hover:bg-brand-1/90"
      onClick={props.onClick}
    >
      <FontAwesomeIcon
        icon={faRefresh}
        className="size-5 text-white transition-transform duration-300 group-hover:rotate-180"
      />
    </Button>
  );
};
