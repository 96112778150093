import { Button, DialogTitle } from '@headlessui/react';
import { useState } from 'react';

import { AddCardDialog } from 'features/add-card/ui';

import { DialogSimple, DialogSimpleProps } from 'shared/ui';

type AddCard = {
  onClick?: () => void;
} & DialogSimpleProps;

export const PayDialog = ({ isOpen, onClose }: AddCard) => {
  const [isOpenNewCard, setIsOpenNewCard] = useState(false);

  return (
    <>
      <DialogSimple isOpen={isOpen} onClose={console.log}>
        <DialogTitle as="h3" className="text-xl font-medium text-white ">
          Способы оплаты
        </DialogTitle>
        <div className="mt-4 flex gap-2">
          <Button className="inline-flex items-center gap-2 rounded-md bg-brand-1 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-200 data-[hover]:text-gray-700">
            Банковской картой
          </Button>
          <Button className="inline-flex items-center gap-2 rounded-md bg-brand-1 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-200 data-[hover]:text-gray-700">
            СБП
          </Button>
        </div>
        <div className="mt-4 flex gap-2">
          <Button
            className="inline-flex items-center gap-2 rounded-md bg-brand-1 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-200 data-[hover]:text-gray-700"
            onClick={onClose}
          >
            Отмена
          </Button>
          <Button
            className="inline-flex items-center gap-2 rounded-md bg-brand-1 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-200 data-[hover]:text-gray-700"
            onClick={() => setIsOpenNewCard(true)}
          >
            Добавить новую карту
          </Button>
        </div>
      </DialogSimple>
      <AddCardDialog
        isOpen={isOpenNewCard}
        onClose={() => setIsOpenNewCard(false)}
      />
    </>
  );
};
