import { cx } from '@emotion/css';

import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FunctionComponent, HTMLAttributes } from 'react';
import { useNavigate } from 'react-router-dom';

import { Spinner } from 'shared/ui';

type ButtonProps = {
  text?: string;
  icon?: any;
  disabled?: boolean;
} & HTMLAttributes<HTMLButtonElement>;

export const Button: FunctionComponent<ButtonProps> = (props) => {
  return (
    <button
      {...props}
      className={cx('px-4 py-2 flex item-center relative', props.className)}
      onClick={props.onClick}
    >
      {props.icon}
      {props.disabled && <Spinner className="size-6 text-white" />}
      {!props.disabled && (props.text || props.children)}
    </button>
  );
};

export const ButtonColored: FunctionComponent<ButtonProps> = (props) => {
  return <Button {...props} className={cx('btn_colored', props.className)} />;
};

export const ButtonPlusIcon: FunctionComponent<ButtonProps> = (props) => {
  return (
    <Button
      className={cx(
        'px-2 flex-center text-white bg-brand-2 hover:bg-brand-2/90 rounded',
        props.className,
      )}
      onClick={props.onClick}
    >
      <FontAwesomeIcon icon={faPlus} className="size-4 pr-2 text-white" />
      {props.text}
    </Button>
  );
};

export const ButtonBack: FunctionComponent<ButtonProps> = (props) => {
  const navigate = useNavigate();
  return (
    <Button
      className="grid h-12 w-fit items-center justify-self-center px-0 text-xl text-white"
      text="Назад"
      onClick={() => navigate(-1)}
    />
  );
};
