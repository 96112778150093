import { cx } from '@emotion/css';

import { format } from 'date-fns';

import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { AddCardDialog } from 'features/add-card/ui';

import { ProfileApi } from 'entities/profile';

import { formatPhoneAsRU, shortenString } from 'shared/lib';
import { Button, Spinner } from 'shared/ui';

export const ProfileInfo = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { data } = useQuery({
    queryKey: [ProfileApi.KEY],
    queryFn: ProfileApi.getProfile,
  });

  if (!data) return <Spinner />;

  return (
    <>
      <div className="flex flex-col justify-center gap-2 rounded-3xl p-8 shadow-2xl">
        <span className="grid grid-cols-[1fr_5fr]">
          <span className="text-lg font-bold">Неизрасходованный лимит:</span>
          <span> {data.remainingPaymentLimit} ₽</span>
        </span>
        <span className="grid grid-cols-[1fr_5fr]">
          <span className="text-lg font-bold">ФИО:</span>
          <span>{data.fullName}</span>
        </span>
        <span className="grid grid-cols-[1fr_5fr]">
          <span className="text-lg font-bold">Дата рождения:</span>
          <span> {format(new Date(data.birthDate), 'DD.mmm.YYYY')}</span>
        </span>
        <span className="grid grid-cols-[1fr_5fr]">
          <span className="text-lg font-bold">E-mail:</span>
          <span>{data.email}</span>
        </span>
        <span className="grid grid-cols-[1fr_5fr]">
          <span className="text-lg font-bold">Номер телефона: </span>
          <span>{formatPhoneAsRU(data.phoneNumber)}</span>
        </span>
        <span className="flex flex-col justify-center gap-1">
          <span className="text-lg font-bold">Карты</span>
          {data?.attachedCreditCards.map((card) => (
            <span className="flex w-2/6 items-center justify-between">
              <span>{shortenString(card)}</span>
              <span>
                <Button
                  className={cx(
                    'inline-flex w-fit items-center gap-2 rounded-md bg-brand-1 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-inner' +
                      'shadow-white/10 focus:outline-none data-[hover]:bg-gray-200 data-[hover]:text-gray-700',
                  )}
                >
                  Удалить
                </Button>
              </span>
            </span>
          ))}
          <Button
            className={cx(
              'inline-flex w-fit items-center gap-2 rounded-md bg-brand-1 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-inner' +
                'shadow-white/10 focus:outline-none data-[hover]:bg-gray-200 data-[hover]:text-gray-700',
            )}
            onClick={() => setIsOpen(true)}
          >
            Добавить новую карту
          </Button>
        </span>
      </div>
      <AddCardDialog isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </>
  );
};
