export const formatPhoneAsRU = (phoneNumber: string) => {
  if (!phoneNumber.startsWith('+7') || phoneNumber.length !== 12) {
    throw new Error('Invalid phone number format');
  }

  const countryCode = phoneNumber.slice(0, 2); // +7
  const part1 = phoneNumber.slice(2, 5); // 906
  const part2 = phoneNumber.slice(5, 8); // 550
  const part3 = phoneNumber.slice(8, 10); // 06
  const part4 = phoneNumber.slice(10); // 67

  return `${countryCode} ${part1} ${part2} ${part3} ${part4}`;
};
