import './styles.scss';
import { cx } from '@emotion/css';

import {
  faClockRotateLeft,
  faCreditCard,
  faUser,
} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useLocation } from 'react-router-dom';

import { RoutesPath } from 'shared/routes-path';

export const SidebarNav = () => {
  const { pathname } = useLocation();

  return (
    <nav className="sidebar__nav">
      <ul className="nav">
        <Link
          className={cx('nav__item item', {
            active: pathname === RoutesPath.HISTORY,
          })}
          to={RoutesPath.HISTORY}
        >
          <FontAwesomeIcon icon={faClockRotateLeft} className="size-4" />
          <span className="item__title">История</span>
        </Link>
        <Link
          className={cx('nav__item item', {
            active: pathname === RoutesPath.INSTALLMENT,
          })}
          to={RoutesPath.INSTALLMENT}
        >
          <FontAwesomeIcon icon={faCreditCard} className="size-4" />
          <span className="item__title">Текущий рассрочки</span>
        </Link>
        <Link
          className={cx('nav__item item', {
            active: pathname === RoutesPath.PROFILE,
          })}
          to={RoutesPath.PROFILE}
        >
          <FontAwesomeIcon icon={faUser} className="size-4" />
          <span className="item__title">Профиль</span>
        </Link>
      </ul>
    </nav>
  );
};
