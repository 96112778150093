import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';

import { PageHeading, PageWrapper } from 'shared/ui';

import { HistoryTable } from './ui';

export const HistoryPage = () => {
  return (
    <PageWrapper className="relative">
      <PageHeading
        icon={faClockRotateLeft}
        title="История"
        refetchQueryFilters={[]}
      />
      <HistoryTable />
    </PageWrapper>
  );
};
