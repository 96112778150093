import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from '@tanstack/react-query';

import { RefreshButton } from 'shared/ui';

export const PageHeading = ({
  icon,
  title,
  refetchQueryFilters,
}: {
  icon: IconDefinition;
  title: string;
  refetchQueryFilters: string[];
}) => {
  const client = useQueryClient();
  const onRefreshClick = () =>
    client.refetchQueries({ queryKey: refetchQueryFilters });

  return (
    <div className="flex items-center gap-3 p-2 text-brand-1">
      <FontAwesomeIcon icon={icon} className="size-8" />
      <h3 className="m-0 text-lg font-medium">{title}</h3>
      <RefreshButton onClick={onRefreshClick} />
    </div>
  );
};
