import { cx } from '@emotion/css';

import type { Cell, Row } from '@tanstack/react-table';
import { flexRender } from '@tanstack/react-table';

import { ActionButton } from './action-button';

export const TableRow = ({
  row,
  onClick,
}: {
  row: Row<any>;
  onClick: (id: string) => void;
}) => {
  return (
    <tr
      className="cursor-pointer border-gray-700 bg-white hover:bg-brand-1/20 [&:not(:last-child)]:border-b"
      onClick={() => onClick(row.id)}
    >
      <td className="px-6 py-2 text-center">
        <span
          className={cx(
            'flex-center m-auto h-[6px] w-[6px] rounded-full',
            !row.original.active ? 'bg-rose-600' : 'bg-green-600',
          )}
        />
      </td>
      {row.getVisibleCells().map((cell: Cell<any, any>) => (
        <td key={cell.id} className="px-6 py-2">
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
      ))}
      <td>
        <ActionButton
          items={[
            { id: '' + 1, name: 'action 1' },
            { id: '' + 2, name: 'action 2' },
            { id: '' + 5, name: 'action 5' },
          ]}
        />
      </td>
    </tr>
  );
};
