import { Profile } from 'entities/profile/model';

import { axiosInstance } from 'shared/api/axios.defaults';

export class ProfileApi {
  static readonly KEY = 'profile';

  static async getProfile(): Promise<Profile> {
    const { data } = await axiosInstance.get(`/profile`);
    return data;
  }
}
