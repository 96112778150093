import { faUser } from '@fortawesome/free-solid-svg-icons';

import { ProfileInfo } from 'pages/profile-page/ui';

import { PageHeading, PageWrapper } from 'shared/ui';

export const ProfilePage = () => {
  return (
    <PageWrapper className="relative">
      <PageHeading icon={faUser} title="Профиль" refetchQueryFilters={[]} />
      <ProfileInfo />
    </PageWrapper>
  );
};
