import { cx } from '@emotion/css';

import { faCheck, faChevronDown } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Field,
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/react';
import { useState } from 'react';

type DropdownItem = {
  label: string;
  id: string;
};

export const TableRowSizeSelect = ({
  setPageSize,
}: {
  setPageSize: (count: number) => void;
}) => {
  return (
    <Field className="flex items-center gap-1 px-4 py-0 text-brand-1">
      <Label className="whitespace-nowrap text-sm/6 font-medium text-brand-1">
        Показывать по
      </Label>
      <SimpleSelect
        items={[10, 50, 100, 1000]
          // .map((item) => (item < total ? item : total))
          .map((item) => ({
            id: String(item),
            label: String(item),
          }))}
        onChange={(item) => {
          setPageSize(Number(item.id));
        }}
      />
    </Field>
  );
};

const SimpleSelect = ({
  items,
  onChange,
}: {
  items: DropdownItem[];
  onChange: (item: { [key: string]: string }) => void;
}) => {
  const [selected, setSelected] = useState(items[0]);

  const _onChange = (item: typeof selected) => {
    setSelected(item);
    onChange?.(item);
  };

  return (
    <Listbox value={selected} onChange={_onChange}>
      <ListboxButton
        className={cx(
          'relative block w-full  rounded-lg border-none bg-brand-1/5 py-1.5 pl-3 pr-8 text-sm/6 text-brand-1',
          'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25',
        )}
      >
        {({ open }) => (
          <>
            {selected.label}
            <FontAwesomeIcon
              icon={faChevronDown}
              className={cx(
                'group pointer-events-none absolute top-2.5 right-2.5 size-4 text-brand-1 transition duration-200 ease-in-out',
                open && 'rotate-180',
              )}
              aria-hidden="true"
            />
          </>
        )}
      </ListboxButton>
      <ListboxOptions
        anchor="bottom"
        transition
        className={cx(
          'w-fit min- mt-[-2px] fixed z-[10] rounded-xl bg-brand-1 backdrop-blur-2xl border border-white/5 p-1 focus:outline-none',
          'transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0',
        )}
      >
        {items.map((item) => (
          <ListboxOption
            key={item.label}
            value={item}
            className="group flex w-full cursor-pointer select-none items-center gap-2 rounded-lg px-3 py-1.5 data-[focus]:bg-brand-1/50"
          >
            <FontAwesomeIcon
              icon={faCheck}
              className="invisible size-4 text-white group-data-[selected]:visible"
            />
            <div className="text-sm/6 text-white">{item.label}</div>
          </ListboxOption>
        ))}
      </ListboxOptions>
    </Listbox>
  );
};
