import { faGear } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';

type ActionButtonProps = {
  items: { id: string; name: string }[];
};

export const ActionButton = ({ items }: ActionButtonProps) => {
  return (
    <Popover className="size-full flex-center">
      <PopoverButton className="block text-sm/6 font-semibold text-brand-3 focus:outline-none ">
        <FontAwesomeIcon
          icon={faGear}
          className="size-6 data-[active]:text-brand-2 data-[hover]:text-brand-3"
        />
      </PopoverButton>
      <PopoverPanel
        transition
        anchor="bottom end"
        className="divide-y divide-brand-4 rounded-xl bg-white/5 text-sm/6 backdrop-blur-sm transition duration-200 ease-in-out [--anchor-gap:var(--spacing-5)] data-[closed]:-translate-y-1 data-[closed]:opacity-0"
      >
        <div className="left-0 min-w-[200px] bg-brand-1/90 p-3">
          {items.map(({ id, name }) => (
            <a
              key={id}
              className="block rounded-lg px-3 py-2 transition hover:bg-white/5"
              href="#"
            >
              <p className="font-semibold text-white">{name}</p>
              <p className="text-brand-4">Lorem ipsum dolor sit amet.</p>
            </a>
          ))}
        </div>
        <div className="bg-brand-1 p-3">
          <a className="block rounded-lg px-3 py-2 transition" href="#">
            <p className="font-semibold text-white">Lorem</p>
            <p className="text-brand-4">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Reprehenderit, sed.{' '}
            </p>
          </a>
        </div>
      </PopoverPanel>
    </Popover>
  );
};
