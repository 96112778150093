export const enum RoutesPath {
  AUTH = '/auth',
  SETTINGS = '/settings',
  MERCHANTS = '/merchants',
  RECOVERY_PASSWORD = '/recovery-password',
  APPROVE_CODE = '/approve-code',
  CHANGE_PASSWORD = '/change-password',
  PROFILE = '/profile',
  INSTALLMENT = '/installment',
  HISTORY = '/history',
}
