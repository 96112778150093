import { cx } from '@emotion/css';

import { Field, Input, Label } from '@headlessui/react';
import { useQuery } from '@tanstack/react-query';
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useState } from 'react';

import { Pagination, Spinner } from 'shared/ui';

import { TableRow, TableRowSizeSelect } from './ui';

export const Table = ({
  queryKeys,
  queryFn,
  columns,
  thead,
  renderRow,
  onRowClick,
}: any) => {
  const [search, setSearch] = useState('');

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { data: query } = useQuery({
    queryKey: [...queryKeys, pagination.pageIndex, pagination.pageSize, search],
    queryFn: () =>
      queryFn({
        page: pagination.pageIndex,
        size: pagination.pageSize,
        search,
      }),
  });

  const table = useReactTable({
    data: query?.content ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    manualPagination: true,
    pageCount: query?.page.totalPages,
    state: {
      pagination,
      globalFilter: search,
    },
    onPaginationChange: setPagination,
    onGlobalFilterChange: setSearch,
  });

  const onPaginationChange = (pageIndex: number) => {
    setPagination({ ...pagination, pageIndex });
  };

  return (
    <div className="flex flex-1 flex-col gap-2 overflow-y-auto">
      <div className="flex gap-2">
        <Input
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Поиск..."
          className={cx(
            'my-1 block w-[200px] rounded-lg border border-brand-1/5 bg-brand-1/5 py-1.5 px-3 text-sm/6 text-brand-1',
            'focus:outline-none',
          )}
        />
      </div>
      <div className="flex flex-1 flex-col overflow-y-auto">
        <table className="w-full text-left text-sm text-brand-1">
          <thead className="sticky top-0 bg-brand-1 text-xs uppercase text-white">
            {thead}
          </thead>
          <tbody className="h-full overflow-y-auto">
            {!query ? (
              <Spinner />
            ) : (
              table.getRowModel().rows.map((row) => {
                return renderRow ? (
                  renderRow(row.original)
                ) : (
                  <TableRow key={row.id} row={row} onClick={onRowClick} />
                );
              })
            )}
          </tbody>
        </table>
      </div>
      <div className="flex items-center justify-between gap-2">
        <Pagination
          pageIndex={pagination.pageIndex}
          pageCount={query?.page.totalPages ?? 0}
          onChange={onPaginationChange}
        />
        <Field className="flex items-center gap-2">
          <Label className="text-sm/6 font-medium text-brand-1">
            Перейти на страницу:
          </Label>
          <Input
            type="number"
            defaultValue={table.getState().pagination.pageIndex + 1}
            min={1}
            max={query?.page.totalPages}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              onPaginationChange(page);
              // table.setPageIndex(page);
            }}
            className={cx(
              'flex w-fit rounded-lg border-none bg-brand-1/5 py-1.5 px-3 text-sm/6 text-brand-1',
              'focus:outline-none hover:bg-brand-1/5 ',
            )}
          />
        </Field>
        <TableRowSizeSelect setPageSize={table.setPageSize} />
      </div>
    </div>
  );
};
