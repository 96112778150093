import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { AuthApi } from 'shared/api';
import { useAuthContext } from 'shared/hooks';
import { RoutesPath } from 'shared/routes-path';
import { Button } from 'shared/ui';

export const LogoutButton = () => {
  const { setIsAuth } = useAuthContext();
  const navigate = useNavigate();

  const logoutMutation = useMutation({
    mutationFn: AuthApi.signOut,
    onMutate: () => {
      setIsAuth(false);
      navigate(RoutesPath.AUTH);

      setTimeout(() => {
        sessionStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
      });
    },
  });

  const onClick = () => {
    logoutMutation.mutate();
  };

  return (
    <Button
      className="rounded border border-white text-white flex-center"
      onClick={onClick}
      disabled={logoutMutation.isPending}
    >
      <span className="pr-2 font-medium">Выход</span>
      <FontAwesomeIcon icon={faArrowRightFromBracket} className="size-4" />
    </Button>
  );
};
