import { cx } from '@emotion/css';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup.js';
import * as yup from 'yup';
import { InferType } from 'yup';

import { Button, DialogTitle, Field, Input, Label } from '@headlessui/react';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { CardApi } from 'entities/card';

import { onAxiosError } from 'shared/lib';
import {
  DialogSimple,
  DialogSimpleProps,
  Button as SharedButton,
} from 'shared/ui';

type AddCard = {
  onClick?: () => void;
} & DialogSimpleProps;

const schema = yup.object({
  newCard: yup.string().trim().min(16).required(),
});

export const AddCardDialog = ({ isOpen, onClose }: AddCard) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const addUserMutation = useMutation({
    mutationFn: (data: InferType<typeof schema>) => CardApi.addCard(data),
    onSuccess: () => {
      toast.success('Карта добавлена');
      onClose();
    },
    onError: onAxiosError,
  });

  const onSubmit = (data: InferType<typeof schema>) => {
    addUserMutation.mutate(data);
  };

  return (
    <DialogSimple isOpen={isOpen} onClose={console.log}>
      <DialogTitle as="h3" className="text-xl font-medium text-white">
        Добавление новой карты
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Field className="mt-3">
          <Label className="text-sm/6 font-medium text-white">
            Номер карты
          </Label>
          <Input
            {...register('newCard')}
            className={cx(
              'block w-full rounded-lg border-none bg-white/5 py-1.5 px-3 text-sm/6 text-white',
              'focus:outline-none',
            )}
          />
        </Field>
        <div className="mt-4 flex gap-2">
          <Button
            className="inline-flex items-center gap-2 rounded-md bg-brand-1 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-200 data-[hover]:text-gray-700"
            onClick={onClose}
          >
            Отмена
          </Button>
          <SharedButton
            className="inline-flex items-center gap-2 rounded-md bg-brand-2 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-brand-2/60 data-[open]:bg-brand-2/70 data-[focus]:outline-1 data-[focus]:outline-white"
            disabled={addUserMutation.isPending}
          >
            Добавить
          </SharedButton>
        </div>
      </form>
      <div className="mt-3 flex flex-col gap-1">
        {errors &&
          Object.values(errors).map(({ message }) => (
            <span key={message} className="text-sm/6 font-medium text-rose-400">
              {message}
            </span>
          ))}
      </div>
    </DialogSimple>
  );
};
