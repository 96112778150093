import axios from 'axios';

import { SITE_URL, XSessionIdKey } from 'shared/contants';
import { delay } from 'shared/lib';

export const axiosInstance = axios.create();

axiosInstance.defaults.baseURL = SITE_URL + '/api';

axiosInstance.interceptors.request.use(
  (config) => {
    const sessionId = sessionStorage.getItem(XSessionIdKey);
    const bearer = sessionStorage.getItem('accessToken');

    if (!!sessionId) config.headers[XSessionIdKey] = sessionId;
    if (!!bearer) config.headers['Authorization'] = `Bearer ${bearer}`;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (
      (error.response.status === 401 || error.response.status === 403) &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      const refreshToken = localStorage.getItem('refreshToken');

      if (!refreshToken) return;

      await delay(5000);
      const { accessToken, newRefreshToken } = await refreshAccessToken(
        // @ts-ignore
        refreshToken,
      );

      sessionStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', newRefreshToken);

      originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
      return axios(originalRequest);
    }

    return Promise.reject(error);
  },
);

async function refreshAccessToken(refreshToken: string) {
  try {
    const response = await axiosInstance.post('/auth/refresh-token', {
      refreshToken,
    });

    const { accessToken, refreshToken: newRefreshToken } = response.data;

    sessionStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', newRefreshToken);

    return { accessToken, newRefreshToken };
  } catch (error) {
    console.error('Failed to refresh access token', error);
    throw error;
  }
}
